import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/title_image"

const IndexPage = () => (
  <Layout>
    <h1>Index</h1>
    <div style={{ maxWidth: `600px`, marginBottom: `1.45rem` }}>
      <Image />
	</div>
	 <p><Link to="/scores/">Scoreboard</Link></p>
	<p><Link to="/puzzles/">See all puzzles</Link></p>

        <br/>
	<Link to="/about/">About this site</Link>
	<br/> <br/>
</Layout>
	
)

export default IndexPage
